import React, { memo } from 'react'
import { Icon } from 'antd'

import './styles.less'


function ExternalLink({ children, to }) {
  return (
    <a href={to} target={`__blank`} rel={`noreferrer`}>{children} <Icon type={`switcher`}/></a>
  )
}

export default memo(ExternalLink)