import React, { memo } from 'react'
import { Map, Marker } from 'react-amap'
import './styles.less'

function MyMap({ lat, long }) {
  return (
    <div className={`map`}>
      <Map
        amapkey={process.env.GATSBY_APP_AMAP_API_KEY}
        center={{ longitude: long, latitude: lat }}
        zoom={16}
      >
        <Marker location={{ longitude: long, latitude: lat }}/>
      </Map>
    </div>
  )
}

export default memo(MyMap)