import React from 'react'
import { navigate } from 'gatsby'
import { List } from 'antd'
import './styles.less'


const Placeholder = ({ children }) => (<div className="ant-list-empty-text">
    <div className="ant-empty ant-empty-normal">
      <div className="ant-empty-image"><img alt="No Data"
                                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHZpZXdCb3g9IjAgMCA2NCA0MSIgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K"/>
      </div>
      <p className="ant-empty-description">{children}</p></div>
  </div>
)

function SearchResults({ query, results, handleClose }) {
  const resultsMessage = query
    ? `No results found for "${query}"`
    : `Type some words into the search box above and get instant results…`
  return (
    <List
      locale={{ emptyText: <Placeholder>{resultsMessage}</Placeholder> }}
      className={`search-results`}
      itemLayout="horizontal"
      dataSource={results}
      renderItem={item => {
        const handleClick = () => {
          handleClose()
          navigate(item.slug)
        }
        return (
          <List.Item className={`search-result`} onClick={handleClick}>
            <List.Item.Meta
              title={<span className={`search-result__title`}>{item.title}</span>}
              description={<span className={`search-result__summary`}>{item.summary}</span>}
            />
          </List.Item>
        )
      }}
    />)
}

export default SearchResults