import { Typography } from "antd";
import { UsefulAppsTable } from "../../../../src/components/Tables";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { FreeSoftwareSteps } from "../../../../src/components/StepperExamples/FreeSoftwareSteps";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import React from 'react';
export default {
  Typography,
  UsefulAppsTable,
  ExternalLink,
  FreeSoftwareSteps,
  Copyable,
  InfoBox,
  React
};