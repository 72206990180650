import React, { memo } from 'react'
import { Icon } from 'antd'
import './styles.less'

function EmailAddress({ address, children }) {
  return (
    <a className={`email-address`} href={`mailto:${address}`} target={'_blank'}>{children} <Icon type={`form`}/></a>
  )
}

export default memo(EmailAddress)

