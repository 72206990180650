import React, { useState } from 'react'
import { Steps } from 'antd'
import './styles.less'

export default function Stepper({ children }) {

  const [current, setCurrent] = useState(0)

  const handleChange = step => {
    setCurrent(step)
  }
  return (
    <Steps current={current} onChange={handleChange} direction="vertical">
      {children}
    </Steps>
  )
}