import { useState, useEffect } from 'react'


export default function useWindowWidth() {
  const [width, setWidth] = useState(0)

  const handleResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    if (window) {
      if (width === 0) {
        handleResize()
      }
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  })

  return width
}