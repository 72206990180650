import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Index } from 'elasticlunr'

const Context = React.createContext(false)

export const searchQuery = graphql`query
SearchIndexExampleQuery {
  siteSearchIndex {
    index
  }
}`

function SearchContextProvider({ children }) {
  const data = useStaticQuery(searchQuery)
  const index = Index.load(data.siteSearchIndex.index)
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const [results, setResults] = useState(false)


  useEffect(() => {
    const results = index.search(query)
      .map(({ ref }) => index.documentStore.getDoc(ref))
    setResults(results)
  }, [query])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (evt) => {
    setQuery(evt.target.value)
  }

  const handleClear = (evt) => {
    setQuery(``)
  }

  return (
    <Context.Provider value={{ open, handleOpen, handleClose, query, handleChange, handleClear, results }}>
      {children}
    </Context.Provider>
  )
}

export { SearchContextProvider, Context as default }