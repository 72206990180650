import React from 'react'
import { Layout, BackTop } from 'antd'
import Sidebar from '../components/Sidebar'
import Providers from '../components/Providers'
import SearchDrawer from '../components/SearchDrawer'
import Loader from '../components/Loader'

const { Footer } = Layout

function PageLayout(props) {
  // console.log('api key in index', process.env.GATSBY_AMPLITUDE_ANALYTICS_API_KEY)
  const { location, children } = props

  return (
    <Providers pathname={location.pathname}>
      {typeof window !== 'undefined'
        ? <Layout>
          <Sidebar/>
          <SearchDrawer/>
          <Layout style={{ backgroundColor: `white` }}>
            {children}
            <Footer>Last updated on May 2, 2020</Footer>
          </Layout>
          <BackTop/>
        </Layout>
        : <Loader/>}
    </Providers>
  )
}

export default PageLayout