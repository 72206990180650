import React, { memo } from 'react'
import Card from 'antd/es/card'
import useWindowWidth from '../../hooks/useWindowWidth'
import { breakpoint } from '../../constants'

AddressCard.propTypes = {}

function AddressCard() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= breakpoint

  return (
    <div style={{ width: `100%`, display: `flex`, justifyContent: `center` }}>
      <Card size={isMobile ? `small` : `medium`}
            title="Tsinghua mailing address"
            style={{ width: 300, margin: `14px 0` }}
      >
        北京 北京市 海淀区 清华园街道 <br/>
        清华大学紫荆学生公寓14号楼<br/>
        编号：100084<br/>
        手机：<strong>your phone number</strong><br/>
        姓名：<strong>your name</strong><br/>
      </Card>
    </div>
  )
}

export default memo(AddressCard)