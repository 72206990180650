import React, { memo } from 'react'
import List from 'antd/es/list'
import useWindowWidth from '../../hooks/useWindowWidth'
import { breakpoint } from '../../constants'
import Copyable from '../Copyable/Copyable'

const data = [
  <li>Bank name: <Copyable>BANK OF CHINA BEIJING BRANCH</Copyable></li>,
  <li>Bank address: <Copyable>NO. 2 Chao Yang Men Nei Da Jie, Dongcheng District, Beijing 100010,
    China</Copyable></li>,
  <li>SWIFT code: <Copyable>BKCH CN BJ 110</Copyable></li>,
  <li>Beneficiary name: <strong>(your legal full name in the format associated with your Bank of China account, use spaces between each word, no abbreviations)</strong></li>,
  <li>Beneficiary account number: <strong>(19-digit account number on your Bank of China card) </strong></li>,
  <li>Supplementary information: <Copyable>Living expenses</Copyable></li>,
]

function WireCard() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= breakpoint

  return (
    <div style={{ width: `100%`, display: `flex`, justifyContent: `center` }}>
        <List
          size={isMobile ? `default` : `large`}
          bordered
          dataSource={data}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
    </div>
  )
}

export default memo(WireCard)