import React from 'react'
import PathnameContext from '../../contexts/Pathname'
import { SearchContextProvider } from '../../contexts/Search'


function Providers({ pathname, children }) {
  return (
    <PathnameContext.Provider value={pathname}>
      <SearchContextProvider>
        {children}
      </SearchContextProvider>
    </PathnameContext.Provider>
  )
}

export default Providers