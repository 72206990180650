import React from 'react'
import { Table } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { tabletBreakpoint } from '../../constants'
import ExternalLink from '../ExternalLink/ExternalLink'


const columns = [{
  title: 'Name',
  dataIndex: 'title',
  key: 'title',
},
  {
    title: 'Map',
    dataIndex: 'location',
    key: 'location',
    render: code => !code
      ? ``
      : <ExternalLink to={`https://ditu.amap.com/place/${code}`}>Map</ExternalLink>,
  },
  {
    title: 'Phone #',
    dataIndex: 'phone',
    key: 'phone',
    render: digits => {
      return (
        <Copyable>{digits}</Copyable>)
    },
  },
]

const data = [{
  key: `1`,
  title: `Campus Telephone Directory`,
  location: ``,
  phone: `6279 3001`,
}, {
  key: `2`,
  title: `Zijing Dormitory Students Comprehensive Office`,
  location: `B0FFFEFJHG`,
  phone: `6278 3996`,
}, {
  key: `3`,
  title: `International Students Apartment Front Desk`,
  location: `B0FFF0EG1K`,
  phone: `5153 5501`,
}, {
  key: `4`,
  title: `Registrar: IC Card`,
  location: `B000AA0VLN`,
  phone: `6279 4720`,
}, {
  key: `5`,
  title: `Registrar: Registration`,
  location: `B000AA0VLN`,
  phone: `6277 3044`,
}, {
  key: `6`,
  title: `Registrar: Choosing Classes`,
  location: `B000AA0VLN`,
  phone: `6278 7169`,
}, {
  key: `7`,
  title: `Registrar: Transcripts`,
  location: `B000AA0VLN`,
  phone: `6278 8112`,
},
  {
    key: `8`,
    title: `Undergraduate Admissions Office`,
    location: `B000A80Z3T`,
    phone: `6278 3100`,
  },
  {
    key: `9`,
    title: `Graduate Admissions Office`,
    location: `B0FFF0EG1K`,
    phone: `6278 1380`,
  },
  {
    key: `10`,
    title: `Academic Affairs: Division of Student
Study Status Administration`,
    location: `B0FFF0EG1K`,
    phone: `6279 4180`,
  },
  {
    key: `11`,
    title: `Graduate Scholarship and Grants Management Office`,
    location: `B0FFF0EG1K`,
    phone: `6278 9660`,
  },
  {
    key: `12`,
    title: `Visiting/Exchange Student Program`,
    location: `B0FFF0EG1K`,
    phone: `6277 3508`,
  },
  {
    key: `13`,
    title: `Chinese Language Program`,
    location: `B0FFJDG378`,
    phone: `6277 1368`,
  },
]

function OfficeTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= tabletBreakpoint
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={columns}
    />
  )
}

export default OfficeTable