import React from 'react'
import { Table } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { tabletBreakpoint } from '../../constants'

const columns = [{
  title: 'Contact',
  dataIndex: 'title',
  key: 'title',
}, {
  title: 'Phone #',
  dataIndex: 'phone',
  key: 'phone',
  render: digits => {
    return (
      <Copyable>{digits}</Copyable>)
  },
},
]

const data = [{
  key: '1',
  title: `Campus Police`,
  phone: `62782001`,
}, {
  key: '2',
  title: `Campus Hospital (emergencies)`,
  phone: `62782185`,
}, {
  key: '3',
  title: `Police Department`,
  phone: `110`,
},
  {
    key: '4',
    title: `Fire Department`,
    phone: `119`,
  },
  {
    key: '5',
    title: `Traffic Police`,
    phone: `122`,
  },
  {
    key: '6',
    title: `Ambulance`,
    phone: `120/999`,
  },
  {
    key: '7',
    title: `Zhongguancun Police Station`,
    phone: `62554600`,
  },
  {
    key: '8',
    title: `Dongsheng Police Station`,
    phone: `62329664`,
  },
  {
    key: '9',
    title: `Qinglong Bridge Police Station`,
    phone: `62881666`,
  },
]

function EmergencyTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= tabletBreakpoint
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={columns}
    />
  )
}

export default EmergencyTable