import React from 'react'

import { Table } from 'antd'
import useWindowWidth from '../../../hooks/useWindowWidth'
import { tabletBreakpoint } from '../../../constants'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import './styles.less'

const imagesQuery = graphql`
    {
        allFile(
            sort: { order: ASC, fields: name},
            filter:{relativeDirectory: {eq: "thu-account-qr"}}
        ) {
            nodes {
                ...GatsbyFluidImageQuery
            }
        }
    }
`

const columns = [{
  title: `Account`,
  dataIndex: `account`,
  key: `account`,
}, {
  title: 'Description',
  dataIndex: 'description',
  key: 'description',
},
]

const textData = [{
  key: 'a',
  title: 'TsinghuaUni',
  description: `Official English language account of Tsinghua University, operated by the Global Communication Office. Provides recent Tsinghua news and events and important updates.`,
}, {
  key: 'b',
  title: `xiaowuyeyuanthu`,
  description: `Tsinghua Xiaowu Yeyuan is a student platform supervised by the Student Activities Office to share information of  interest to students.`,
}, {
  key: 'c',
  title: `thuxiaoyanonline`,
  description: `Organized by the Graduate Students Union. Xiaoyan provides a vast array of information relating to campus life.`,
}, {
  key: 'd',
  title: `issc2016`,
  description: `Official account of the International Students & Scholars  Center. Provides important information on campus life  and study, including visas, accommodation, insurance and  careers and employment. It also is a window into the rich and colorful campus life of international students, and  provides news and information on activities, lectures and student interviews.`,
},
  {
    key: 'e',
    title: `Renwen_Tsinghua`,
    description: `Official account for the Humanities at Tsinghua series. Provides  information on upcoming lectures. Humanities at Tsinghua  lectures are large-scale public lectures that invite renowned  scholars and public figures in the humanities to give talks on  classic theories, unique thoughts and new findings.`,
  },
  {
    key: 'f',
    title: `SchwarzmanScholars`,
    description: `The official account of Schwarzman College. Provides information on the latest events held at the college.`,
  },
  {
    key: 'g',
    title: `Xinqinghuaxuetang`,
    description: `Official account of the New Tsinghua Xuetang. Publishes information on upcoming performances and movies held at Tsinghua`,
  },
  {
    key: 'h',
    title: `shetuan_THU`,
    description: `Official account for the Student Associations Department. Shares information on the 200+ student associations at Tsinghua, including their events and stories.`,
  },
  {
    key: 'i',
    title: `Aicethu2005`,
    description: `The official account for the Association of International Culture Exchange (AICE), a student society with the aim to bring together and promote connections between local and  international students by hosting events.`,
  },
  {
    key: 'j',
    title: `Gh_c9d071d35f01`,
    description: `Tsinghua Guoji is produced by Tsinghua University's Office of International Cooperation and Exchange, and is a window for Chinese readers in China and abroad to learn of Tsinghua's cooperation internationally and also with Hong Kong, Macao and Taiwan.`,
  },
  {
    key: 'k',
    title: `Qhdxgjjy`,
    description: `Official WeChat account for the Office of International Education at Tsinghua University, which was founded in July 2015 to further enhance the cultivation of students using an international  approach, so as to enhance students' global competence and heighten the inﬂuence of Tsinghua worldwide.`,
  },
]

const QRElement = ({ title, fluid }) => (<div className={`qr-table-cell`}>
  <Img fluid={fluid}/>
  <p style={{ textAlign: `center` }}>{title}</p>
</div>)

function QRTable() {
  const windowWidth = useWindowWidth()
  const { allFile } = useStaticQuery(imagesQuery)

  const data = allFile.nodes.map(({ childImageSharp }, idx) => {
    const { fluid } = childImageSharp
    return ({
      account: <QRElement title={textData[idx][`title`]} fluid={fluid}/>,
      description: textData[idx][`description`],
      key: textData[idx][`key`],
    })
  })

  const isMobile = windowWidth <= tabletBreakpoint
  const tableCols = isMobile
    ? columns.filter(obj => obj.key !== `description`)
    : columns
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={tableCols}
    />
  )
}

export default QRTable