import React from 'react'

import { Table } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { desktopBreakpoint } from '../../constants'

const columns = [{
  title: 'Software',
  dataIndex: 'english',
  key: 'english',
}, {
  title: '软件',
  dataIndex: 'chinese',
  key: 'chinese',
  render: text => <Copyable>{text}</Copyable>,
}, {
  title: 'Contains',
  dataIndex: 'example',
  key: 'example',
}]

const data = [{
  key: '1',
  english: 'Operating Systems',
  chinese: '操作系统',
  example: 'Windows 10 activation keys',
}, {
  key: '2',
  english: 'AntiVirus',
  chinese: '反病毒软件',
  example: 'Kaspersky, NOD32',
}, {
  key: '3',
  english: 'Office',
  chinese: '办公软件',
  example: 'Word, Excel, Powerpoint',
}, {
  key: '4',
  english: 'Developer',
  chinese: '开发软件',
  example: 'Visual Studio Pro',
}, {
  key: '5',
  english: 'Math/Stat',
  chinese: '计算软件',
  example: 'Matlab',
}]

function SoftwareTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= desktopBreakpoint
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={columns}
    />
  )
}

export default SoftwareTable