import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "info-language"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Select <Copyable>账户设置</Copyable> (account settings) from the dropdown menu in the upper right corner of the landing page.</div>,
  <div>Select English and click the submit button below.</div>,

]

export function InfoLanguageSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}