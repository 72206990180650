import React, { memo, useState } from 'react'
import './styles.less'
import Checkbox from 'antd/es/checkbox'


function ChecklistItem({ children, extra }) {
  const [checked, setChecked] = useState(false)

  const handleClick = () => {
    setChecked(!checked)
  }
  return (
    <div className={`checklistitem`}>
      <Checkbox onClick={handleClick} className={`checklistitem__box`} checked={checked}/>
      <div className={`checklistitem__label 
      ${checked ? `checklistitem__label--checked` : ``}`}>
        <span onClick={handleClick}>{children}</span>{extra}
      </div>
    </div>)
}


export default memo(ChecklistItem)