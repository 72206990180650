import { Typography } from "antd";
import Copyable from "../../../../src/components/Copyable";
import { OfficesTable } from "../../../../src/components/Tables";
import InfoBox from "../../../../src/components/InfoBox";
import React from 'react';
export default {
  Typography,
  Copyable,
  OfficesTable,
  InfoBox,
  React
};