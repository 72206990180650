import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DocumentDialog from '../DocumentDialog'
import Img from 'gatsby-image'


const docQuery = graphql`
    query {
        placeholderImage: file(relativePath: { eq: "medical-exam.png" }) {
            ...GatsbyFluidImageQuery
        }
    }
`

const MedicalExam = ({ children }) => {
  const data = useStaticQuery(docQuery)
  return (<DocumentDialog
    title={`Medical Exam Results`} image={<Img fluid={data.placeholderImage.childImageSharp.fluid}/>}>{children}
  </DocumentDialog>)
}

export { MedicalExam }