import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "internet-topup"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Click <Copyable>自助缴费</Copyable> on the right side menu (note this landing page also shows your current balance, see the smaller red arrow in the screen shot).</div>,
  <div>After you click <Copyable>自助缴费</Copyable>, enter the amount you'd like to add to your account.</div>,

]

export function InternetSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://usereg.tsinghua.edu.cn`}>usereg.tsinghua.edu.cn</ExternalLink>. This site is easier to navigate on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
      <Step description={`Select the payment method (WeChat or Alipay) and complete payment. Note that it may take a couple minutes for you account to reflect the updated balance.`}/>
    </Stepper>)
}