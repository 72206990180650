import React from 'react'
import { Link } from 'gatsby'
import './styles.less'
import logo from '../../images/logo.svg'


function Logo() {
  return (
    <Link to={`/`}>
    <div className={`logo`}>
      <img alt={`site logo`} style={{ width: `100%` }} src={logo}/>
    </div>
    </Link>
  )
}

export default Logo
