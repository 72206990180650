import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "file-sharing"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Search the landing page (Cmd + F) for <Copyable>虚拟空间</Copyable> virtual space on the left sidebar. If you agree
    to the terms and conditions, click the <Copyable>同意</Copyable> agree button on the right of the pop-up dialog.
  </div>,
  <div>From here, you can upload and share files from your local storage to network. Click the <Copyable>上传文件</Copyable> button (middle button in the top row) to get started.</div>

]

export function SharingSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}