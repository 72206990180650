import { Divider, Typography } from "antd";
import UsefulAppsTable from "../../../../src/components/Tables/UsefulAppsTable";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import Checklist from "../../../../src/components/Checklist";
import ChecklistItem from "../../../../src/components/ChecklistItem";
import { AdmissionsCert } from "../../../../src/components/DocumentExamples";
import React from 'react';
export default {
  Divider,
  Typography,
  UsefulAppsTable,
  ExternalLink,
  Copyable,
  InfoBox,
  Checklist,
  ChecklistItem,
  AdmissionsCert,
  React
};