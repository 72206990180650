import React from 'react'

import { Table, Tag } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { tabletBreakpoint, primaryColor, green6, gold6 } from '../../constants'
import ExternalLink from '../ExternalLink/ExternalLink'

const getMethodColor = (method) => {
  switch (method) {
    case(`Purple`): {
      return primaryColor
    }
    case(`Green`): {
      return green6
    }
    default: {
      return gold6
    }
  }
}

const columns = [{
  title: 'Name',
  dataIndex: 'title',
  key: 'title',
  render: titles => {
    return (
      <div>
        <div><ExternalLink to={`https://ditu.amap.com/place/${titles[2]}`}>{titles[1]}</ExternalLink></div>
        <Copyable>{titles[0]}</Copyable>
      </div>)
  },
}, {
  title: 'Hours',
  dataIndex: 'hours',
  key: 'hours',
  render: hours => (
    <span>
        {hours.map(hours => (<Tag key={hours}>
          {hours}
        </Tag>))}
      </span>
  ),
},
  {
    title: 'Maps',
    dataIndex: 'location',
    key: 'location',
    render: code => <ExternalLink to={`https://ditu.amap.com/place/${code}`}>Map</ExternalLink>,
  },
  {
    title: 'Payment',
    key: 'methods',
    dataIndex: 'methods',
    render: methods => (
      <span>
        {methods.map(method => {
          const color = getMethodColor(method)
          return (
            <Tag color={color} key={method}>
              {method.toUpperCase()}
            </Tag>
          )
        })}
      </span>
    ),
  },
]

const data = [{
  key: '1',
  title: ['观畴园', 'Guānchóu yuán', `B000A7P667`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 19:00'],
  location: `B000A7P667`,
  methods: ['Purple', 'Green'],
}, {
  key: '2',
  title: ['桃李园', 'Táolǐ yuán', `B0FFFYK9KR`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 22:30'],
  location: `B0FFFYK9KR`,
  methods: ['Purple', 'Green'],
}, {
  key: '3',
  title: ['紫荆园', 'Zǐjīng yuán', `B0FFF4NS7W`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 19:00'],
  location: `B0FFF4NS7W`,
  methods: ['Purple'],
}, {
  key: '4',
  title: ['听涛园', 'Tīngtāo yuán', `B000A80T1V`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 19:00'],
  location: `B000A80T1V`,
  methods: ['Purple'],
}, {
  key: '5',
  title: ['丁香园', 'Dīngxiāng yuán', `B0FFFALVO0`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 19:00'],
  location: `B0FFFALVO0`,
  methods: ['Purple'],
}, {
  key: '6',
  title: ['清芬园', 'Qīngfēn yuán', `B0FFFFK4EO`],
  hours: ['06:30 - 9:00', '11:00 - 13:00', '17:00 - 19:00'],
  location: `B0FFFFK4EO`,
  methods: ['Purple'],
}, {
  key: '7',
  title: ['玉树园', 'Yùshù yuán', `B000A81JO9`],
  hours: ['06:40 - 8:30', '11:00 - 13:30', '17:00 - 20:30'],
  location: `B000A81JO9`,
  methods: ['Purple', 'Green'],
}, {
  key: '8',
  title: ['芝兰园', 'Zhīlán yuán', `B000AA57FG`],
  hours: ['11:00 - 13:00', '17:00 - 20:00'],
  location: `B000AA57FG`,
  methods: ['Purple'],
}, {
  key: '10',
  title: ['清真餐厅', 'Muslim Canteen', `B0FFFFJM0E`],
  hours: ['10:30 - 22:30'],
  location: `B0FFFFJM0E`,
  methods: ['Purple', 'Green'],
}, {
  key: '11',
  title: ['清青快餐', 'Qīngqīng Fast Food', `B0FFFFK4EO`],
  hours: ['10:30 - 22:30'],
  location: `B0FFFFK4EO`,
  methods: ['Purple', 'Green', 'Cash'],
}, {
  key: '12',
  title: [`清青比萨`, `Qīngqīng pizza`, `B0FFF4NS7W`],
  hours: ['10:30 - 22:00'],
  location: `B0FFF4NS7W`,
  methods: ['Purple', 'Green', 'Cash'],
},
  {
    key: '13',
    title: ['清青永和', 'Qīngqīng yǒnghé', `B000A7P667`],
    hours: ['06:30 - 14:00', '16:30 - 21:30'],
    location: `B000A7P667`,
    methods: ['Purple', 'Green'],
  }, {
    key: '14',
    title: ['清青休闲餐厅', 'Qīngqīng xiūxián cāntīng', `B0FFFYK9KR`],
    hours: ['06:30 - 14:00', '16:30 - 21:30'],
    location: `B0FFFYK9KR`,
    methods: ['Purple', 'Green'],
  },
]

function CanteenTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= tabletBreakpoint
  const tableCols = isMobile
    ? columns.filter(obj => obj.key !== `methods` && obj.key !== `location`)
    : columns
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={tableCols}
    />
  )
}

export default CanteenTable