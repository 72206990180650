import { Typography } from "antd";
import Copyable from "../../../../src/components/Copyable";
import { EmergencyTable } from "../../../../src/components/Tables";
import InfoBox from "../../../../src/components/InfoBox";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import React from 'react';
export default {
  Typography,
  Copyable,
  EmergencyTable,
  InfoBox,
  ExternalLink,
  React
};