import React from 'react'

import { Table } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { desktopBreakpoint } from '../../constants'

const columns = [{
  title: 'App',
  dataIndex: 'english',
  key: 'english',
}, {
  title: '应用',
  dataIndex: 'chinese',
  key: 'chinese',
  render: text => <Copyable>{text}</Copyable>
}, {
  title: 'Equivalent',
  dataIndex: 'equivalent',
  key: 'equivalent',
}, {
  title: 'Description',
  dataIndex: 'description',
  key: 'description',
}
];

const data = [{
  key: '1',
  english: 'Amap',
  chinese: '高德地图',
  equivalent: 'Google Maps',
  description: 'Maps and directions'
}, {
  key: '2',
  english: 'Didi',
  chinese: '滴滴出行',
  equivalent: 'Uber',
  description: 'Call cabs, black cars, personal drivers, etc.'
}, {
  key: '3',
  english: 'Taobao',
  chinese: '淘宝',
  equivalent: 'ebay',
  description: 'Buy stuff from small individual vendors'
}, {
  key: '4',
  english: 'T-Mall',
  chinese: '天猫',
  equivalent: 'Amazon',
  description: 'Buy stuff from big companies'
}, {
  key: '6',
  english: 'Pleco',
  chinese: '普利科',
  equivalent: '-',
  description: 'Best app for studying Chinese'
}, {
  key: '7',
  english: 'eleme',
  chinese: '饿了么',
  equivalent: 'Seamless',
  description: 'Food ordering and delivery'
}, {
  key: '8',
  english: 'Meituan',
  chinese: '美团外卖',
  equivalent: 'Seamless',
  description: 'Food ordering and delivery'
}, {
  key: '9',
  english: 'Alipay',
  chinese: '支付宝',
  equivalent: 'Venmo',
  description: 'Mobile payments'
}, {
  key: '10',
  english: 'WeChat',
  chinese: '微信',
  equivalent: '-',
  description: 'One app to rule them all'
}, {
  key: '11',
  english: 'Mobai',
  chinese: '膜拜单车',
  equivalent: '-',
  description: 'Rent the orange bikes that you see everywhere'
}, {
  key: '12',
  english: 'Dianping',
  chinese: '大众点评',
  equivalent: 'Yelp',
  description: 'Location-based food reviews'
}, {
  key: '13',
  english: 'Microsoft Translate',
  chinese: '微软翻译',
  equivalent: 'Google Translate',
  description: 'Speech to text translation'
}
];

function UsefulAppsTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= desktopBreakpoint
  const tableCols = isMobile
    ? columns.filter(obj => obj.key !== 'description')
    : columns;


  return (
    <Table pagination={false}
           style={{ width: `100%`, marginBottom: `28px` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={tableCols}
    />
  )
}

export default UsefulAppsTable