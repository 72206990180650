import React from 'react'
import MapDialog from '../MapDialog'


export const TravelHealthCareCenter = ({children}) => (
  <MapDialog
    title={'Travel Health Care Center'}
    lat={39.970935}
    long={116.378681}
    amapId={'B0FFF6C87H'}
    englishAddress={'Building 17, Ma Dian East Rd, Jinau International Building'}
    chineseAddress={'北京国际旅行卫生保健中心, 北京市海淀区马甸东路17号金澳国际大'}
  >{children}</MapDialog>
);

export const ISSC = ({ children }) => (<MapDialog
  title={'International Students & Scholars Center'}
  lat={40.010106}
  long={116.333846}
  amapId={'B0FFF0EG1K'}
  englishAddress={'Zijing Apartment Building No.22, First Floor'}
  chineseAddress={'清华大学紫荆学生公寓22号楼'}
>{children}</MapDialog>)

export const VisaBureau = ({ children }) => (<MapDialog
  title={'Bureau of Entry and Exit Administration'}
  lat={39.948888}
  long={116.427373}
  amapId={'B000A54C18'}
  englishAddress={'Building 2, Andingmen Dong Da Road'}
  chineseAddress={'北京市公安局出入境管理局, 北京市东城区安定门东大街2号'}
>{children}</MapDialog>)

export const Registrar = ({ children }) => (<MapDialog
  title={'Tsinghua Registrar Center'}
  lat={40.009872}
  long={116.328062}
  amapId={`B0FFJDG378`}
  englishAddress={`Zijing C Building`}
  chineseAddress={`清华大学C楼`}
>{children}</MapDialog>)

export const DormServiceDesk = ({ children }) => (<MapDialog
  title={'Zijing Dormitory Service Desk'}
  lat={40.011553}
  long={116.332142}
  amapId={'B0FFFEFJHG'}
  englishAddress={'Zijing Dormitory Building 19, 1st floor'}
  chineseAddress={'清华大学紫荆公寓综合服务台'}
>{children}</MapDialog>)

export const PackageDeliveryCenter = ({children}) => (
  <MapDialog
    title={`Package Delivery Center`}
    lat={40.012714}
    long={116.327232}
    amapId={`B0FFFF6O4E`}
    englishAddress={`Behind Zijing Dormitory Building 14`}
    chineseAddress={`紫荆14号楼后面，京东商城货物自提点`}
  >{children}</MapDialog>
);

export const LeeShauKee = ({children}) => (
  <MapDialog
    title={`Lee Shau Kee Building`}
    lat={39.997021}
    long={116.330337}
    amapId={`B000AA1ZCH`}
    englishAddress={`Lee Shau Kee Science & Technology Building`}
    chineseAddress={`李兆基科技大楼`}
  >{children}</MapDialog>
);

export const CBuilding = ({children}) => (
  <MapDialog
    title={`Zijing C Building`}
    lat={40.009872}
    long={116.328062}
    amapId={`B0FFJDG378`}
    englishAddress={`Zijing C Building`}
    chineseAddress={`清华大学C楼`}
  >{children}</MapDialog>
);


export const ComprehensiveGym = ({children}) => (
  <MapDialog
    title={`Tsinghua Comprehensive Gymnasium`}
    lat={40.004464}
    long={116.33237}
    amapId={`B000A8VQWK`}
    englishAddress={`Tsinghua Comprehensive Gymnasium`}
    chineseAddress={`清华大学综合体育中心`}
  >{children}</MapDialog>
);
