import React from 'react'
import { Table, Tag } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { tabletBreakpoint, primaryColor, green6, blue6, gold6 } from '../../constants'
import ExternalLink from '../ExternalLink/ExternalLink'

const getTagColor = (method) => {
  switch (method) {
    case(`Snacks`): {
      return primaryColor
    }
    case(`Produce`): {
      return green6
    }
    case(`Clothing`): {
      return blue6
    }
    case(`More`): {
      return gold6
    }
    default: {
      return null
    }
  }
}

const columns = [{
  title: 'Name',
  dataIndex: 'title',
  key: 'title',
  render: titles => {
    return (
      <div>
        <div><ExternalLink to={`https://ditu.amap.com/place/${titles[2]}`}>{titles[1]}</ExternalLink></div>
        <Copyable>{titles[0]}</Copyable>
      </div>)
  },
}, {
  title: 'Hours',
  dataIndex: 'hours',
  key: 'hours',
},
  {
    title: 'Map',
    dataIndex: 'location',
    key: 'location',
    render: code => <ExternalLink to={`https://ditu.amap.com/place/${code}`}>Map</ExternalLink>,
  },
  {
    title: 'Items',
    key: 'items',
    dataIndex: 'items',
    render: items => (
      items.map(item => <Tag color={getTagColor(item)} key={item}>{item}</Tag>)
    ),
  },
]

const data = [{
  key: '1',
  title: [`天猫校园店—紫荆店`, `Tmall - Zijing`, `B0FFJDG378`],
  hours: `8:30am – 11:30pm`,
  location: `B0FFJDG378`,
  items: [`Snacks`, `Produce`],
}, {
  key: '2',
  title: [`天猫校园店—清芬店`, `Tmall - Qingfen`, `B000A9PIKX`],
  hours: `8:30am - 11:30pm`,
  location: `B000A9PIKX`,
  items: [`Snacks`, `Produce`],
}, {
  key: '3',
  title: [`天猫校园店—观畴店`, `Tmall - Guanchou`, `B000A7P667`],
  hours: `9:00am - 9:00pm`,
  location: `B000A7P667`,
  items: [`Snacks`, `Produce`],
},
  {
    key: '4',
    title: [`照澜院超市`, `Zhaolanyuan Grocery`, `B000A7Q6IZ`],
    hours: `9:00am - 8:00pm`,
    location: `B000A7Q6IZ`,
    items: [`Snacks`, `Produce`],
  },
  {
    key: '5',
    title: [`照澜院农贸市`, `Zhaolanyuan Market`, `B000A7PDM1`],
    hours: `9:00am - 8:00pm`,
    location: `B000A7PDM1`,
    items: [`Snacks`, `Produce`, `Clothing`, `More`],
  },
  {
    key: '7',
    title: [`北区便民果蔬超市`, `North Area Fruit & Vegetable Market`, `B000A7PDM1`],
    hours: `7:30am – 9:00pm`,
    location: `B000A7PDM1`,
    items: [`Snacks`, `Produce`],
  },
  {
    key: '8',
    title: [`易初莲花`, `Lotus`, `B0FFGHEALO`],
    hours: `9:00am – 9:00pm`,
    location: `B0FFGHEALO`,
    items: [`Snacks`, `Produce`, `Clothing`, `More`],
  },
  {
    key: '9',
    title: [`华联`, `BHG`, `B0FFF6X6CI`],
    hours: `9:00am – 9:00pm`,
    location: `B0FFF6X6CI`,
    items: [`Snacks`, `Produce`, `Clothing`, `More`],
  },
  {
    key: '10',
    title: [`家乐福`, `Carrefour`, `B000A834B1`],
    hours: `8:30am – 10:00pm`,
    location: `B000A834B1`,
    items: [`Snacks`, `Produce`, `Clothing`, `More`],
  },
  {
    key: '11',
    title: [`国际超市`, `D-MART`, `B0FFI8E0ZN`],
    hours: `10:00am – 9:00pm`,
    location: `B0FFI8E0ZN`,
    items: [`Snacks`, `Produce`, `Clothing`, `More`],
  },
]

function ShopsTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= tabletBreakpoint
  const tableCols = isMobile
    ? columns.filter(obj => obj.key !== `location` && obj.key !== `items`)
    : columns
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={tableCols}
    />
  )
}

export default ShopsTable