import { Divider, Typography, Card } from "antd";
import Copyable from "../../../../src/components/Copyable";
import { PackageDeliveryCenter } from "../../../../src/components/MapLocations";
import AddressCard from "../../../../src/components/AddressCard/AddressCard";
import React from 'react';
export default {
  Divider,
  Typography,
  Card,
  Copyable,
  PackageDeliveryCenter,
  AddressCard,
  React
};