import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DocumentDialog from '../DocumentDialog'
import Img from 'gatsby-image'


const docQuery = graphql`
    query {
        placeholderImage: file(relativePath: { eq: "registration-form-temp-residence.png" }) {
            ...GatsbyFluidImageQuery
        }
    }
`

const FormOfTemporaryResidence = ({ children }) => {
  const data = useStaticQuery(docQuery)
  return (<DocumentDialog
    title={`Form of Temporary Residence`} image={<Img fluid={data.placeholderImage.childImageSharp.fluid}/>}>{children}
  </DocumentDialog>)
}

export { FormOfTemporaryResidence }