import { Divider, Typography } from "antd";
import WireCard from "../../../../src/components/WireCard";
import { CBuilding } from "../../../../src/components/MapLocations";
import React from 'react';
export default {
  Divider,
  Typography,
  WireCard,
  CBuilding,
  React
};