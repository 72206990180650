import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "off-campus-intranet"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Click <Copyable>WEB方式登录入口</Copyable> (web-method sign-in portal)</div>,
  <div>Enter your Student ID into the top text entry field followed by your password in the field below it</div>,
  <div>From here, you can choose which version of info.tsinghua (i.e. desktop or mobile) you’d like to go to</div>,
]

export function OffCampusIntranetSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>

      <Step description={<div className={`step-contents`}>Navigate to <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}