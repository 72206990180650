import React from 'react'
import image from '../../images/loading.gif'
import './styles.less'

function Loader() {
  return (
    <div className={'loader'}>
      <img alt={`loading animation`} src={image}/>
    </div>
  )
}

export default Loader