import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "ic-topup"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Place your Student ID on the card reader</div>,
  <div>Tap the sign in <Copyable>我要登录</Copyable> button at the top of the sidebar on the left and enter your password. Your password is initialized as the last six digits of your passport number. If your passport
    number is less than six digits, add zeros to the front to pad it to six digits (e.g. “123” becomes “000123”).</div>,
  <div>Tap the account <Copyable>圈存</Copyable> button (it's the third icon from the top on the left menu).</div>,
  <div>Enter amount you want to transfer from your Bank of China account to your to card and tap
    confirm <Copyable>确认</Copyable></div>,
  <div>Tap the confirm <Copyable>确认</Copyable> button on the left of the pop-up dialog and then…
  </div>,
  <div>Don’t forget to sign out by tapping the <Copyable>推出系统</Copyable> button in the lower left on the sidebar</div>,
]

export function ICTopupSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}