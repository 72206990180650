import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "free-software"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Locate and click <Copyable>软件资源</Copyable> on the landing page's sidebar (use Cmd+F to search the
    page).</div>,
  <div>Software downloads are sorted by type (see the table below). Click the orange drop-down arrow to the
    right of the title in each column to view all the downloads available for that type.</div>,
]

export function FreeSoftwareSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>

      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
      <Step description={`Download your software.`}/>
    </Stepper>)
}