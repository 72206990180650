import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import './styles.less'


export const GatsbyImageQuery = graphql`
  fragment GatsbyFluidImageQuery on File {
    childImageSharp {
      id
      fluid(maxWidth: 1440) {
        ...GatsbyImageSharpFluid
      }
    }
  }`

const bannerQuery = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "banner-img.jpg" }) {
      ...GatsbyFluidImageQuery
    }
  }
`

function BannerImage() {
  const { placeholderImage } = useStaticQuery(bannerQuery)
  return (
    <Img style={{ width: `100%`, marginBottom: `14px` }} className={`banner-image`}
         fluid={placeholderImage.childImageSharp.fluid}/>
  )
}

export default BannerImage
