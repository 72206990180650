import React, { memo } from 'react'

import { desktopBreakpoint } from '../../constants'
import useWindowWidth from '../../hooks/useWindowWidth'
import PersistentSidebar from './PersistentSidebar'
import TemporarySidebar from './TemporarySidebar'
import './styles.less'


function Sidebar() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= desktopBreakpoint
  return (
    isMobile
      ? <TemporarySidebar/>
      : <PersistentSidebar/>
  )
}

export default memo(Sidebar)
