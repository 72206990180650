import React, {memo} from 'react';
import { Layout } from 'antd';
import Menu from '../Menu';

const { Sider } = Layout;

function PersistentSidebar() {
    return (
        <Sider

            width={256}
            theme={`light`}
            className={`persistent-sidebar`}>
            <Menu/>
        </Sider>
    );
}

export default memo(PersistentSidebar)