import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DocumentDialog from '../DocumentDialog'
import Img from 'gatsby-image'


const docQuery = graphql`
    query {
        placeholderImage: file(relativePath: { eq: "admissions-cert.jpg" }) {
            ...GatsbyFluidImageQuery
        }
    }
`

const AdmissionsCert = ({ children }) => {
  const data = useStaticQuery(docQuery)
  return (<DocumentDialog
    title={`Certificate of Admission`} image={<Img fluid={data.placeholderImage.childImageSharp.fluid}/>}>{children}
  </DocumentDialog>)
}

export { AdmissionsCert }