import React, { memo } from 'react'
import './styles.less'
import Divider from 'antd/es/divider'


function Checklist({ title, children }) {
  return (
    <div className={`checklist`}>
      <Divider>{title}</Divider>
      <div className={`checklist__body`}>
        {children}
      </div>
    </div>
  )
}

export default memo(Checklist)