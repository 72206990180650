const phoneBreakpoint = 480
const tabletBreakpoint = 600
const desktopBreakpoint = 768
const primaryColor = `#722ed1`
const green6 = `#52c41a`
const blue6 = `#1890ff`
const red6 = `#f5222d`
const gold6 = `#faad14`

const menuLinks = [
  {
    label: `About this guide`,
    key: `home`,
    slug: ``,
    icon: `pushpin`,
  },
  {
    label: `Getting started`,
    key: `getting-started`,
    slug: `getting-started`,
    icon: `thunderbolt`,
    subItems: [
      {
        slug: `visa-application`,
        key: `visa-application`,
        label: `0. Visa application`,
      },
      {
        slug: `before-departure`,
        key: `before-departure`,
        label: `1. Before departure`,
      },
      {
        slug: `getting-to-campus`,
        key: `getting-to-campus`,
        label: `2. Getting to campus`,
      },
      {
        slug: `dorm-check-in`,
        key: `dorm-check-in`,
        label: `3. Dorm check-in`,
      },
      {
        slug: `physical-exam`,
        key: `physical-exam`,
        label: `4. Physical exam`,
      },
      {
        slug: `issc-registration`,
        key: `issc-registration`,
        label: `5. ISSC registration`,
      },
      {
        slug: `ic-card`,
        key: `ic-card`,
        label: `6. Student IC card`,
      },
      {
        slug: `residence-permit`,
        key: `residence-permit`,
        label: `7. Residence permit`,
      },
    ],
  },
  {
    label: `Health & safety`,
    key: `health-and-safety`,
    icon: `alert`,
    slug: `health-and-safety`,
    subItems: [
      {
        slug: `emergency`,
        key: `emergency`,
        label: `Emergencies`,
      },
      {
        slug: `mental-health`,
        key: `mental-health`,
        label: `Mental health`,
      },
      {
        slug: `insurance`,
        key: `insurance`,
        label: `Medical insurance`,
      },

    ],
  },
  {
    label: `Phone numbers`,
    key: `phone-numbers`,
    icon: `phone`,
    slug: `phone-numbers`,
    subItems: [
      {
        slug: `emergency`,
        key: `emergency`,
        label: `Emergency`,
      },
      {
        slug: `offices`,
        key: `offices`,
        label: `Administrative`,
      },
    ],
  },
  {
    label: `Food & shops`,
    slug: `food`,
    key: `food`,
    icon: `rest`,
    subItems: [
      {
        slug: `campus-canteens`,
        key: `campus-canteens`,
        label: `Campus canteens`,
      },
      {
        slug: `coffee-cafe`,
        key: `coffee-cafe`,
        label: `Coffee & cafés`,
      },
      // {
      //   slug: `ic-topup`,
      //   key: `ic-topup`,
      //   label: `IC card topup`,
      // },
      {
        slug: `shops-nearby`,
        key: `shops-nearby`,
        label: `Shops nearby`,
      },
    ],
  },
  {
    label: `Money`,
    slug: `money`,
    key: `money`,
    icon: `dollar`,
    subItems: [
      {
        slug: `bank-account`,
        key: `bank-account`,
        label: `Bank account`,
      },
      {
        slug: `wire-transfer`,
        key: `wire-transfer`,
        label: `Wire transfer`,
      },
    ],
  },
  {
    label: `Academics`,
    slug: `academic`,
    key: `academic`,
    icon: `book`,
    subItems: [
      {
        slug: `apply-to-tsinghua`,
        key: `apply-to-tsinghua`,
        label: `Apply online`,
      },
      {
        slug: `print-transcript`,
        key: `print-transcript`,
        label: `Print transcript`,
      },
    ],
  },
  {
    label: 'Info system',
    slug: 'tsinghua-info',
    key: `tsinghua-info`,
    icon: `setting`,
    subItems: [
      {
        slug: `change-password`,
        key: `change-password`,
        label: `Change password`,
      },
      {
        slug: `file-sharing`,
        key: `file-sharing`,
        label: `File sharing`,
      },
      {
        slug: `free-software`,
        key: `free-software`,
        label: `Free software`,
      },
      {
        slug: `info-language`,
        key: `info-language`,
        label: `Language settings`,
      },
      {
        slug: `off-campus-access`,
        key: `off-campus-access`,
        label: `Off-campus access`,
      },
    ],
  },
  {
    label: 'Technology',
    slug: 'technology',
    key: `technology`,
    icon: `qrcode`,
    subItems: [
      {
        slug: `sim-card`,
        key: `sim-card`,
        label: `SIM cards`,
      },
      {
        slug: `internet`,
        key: `internet`,
        label: `Internet topup`,
      },
      {
        slug: `useful-apps`,
        key: `useful-apps`,
        label: `Useful apps`,
      },
      {
        slug: `wechat-qr`,
        key: `wechat-qr`,
        label: `WeChat accounts`,
      },
    ],
  },
  {
    label: `Miscellaneous`,
    key: `miscellaneous`,
    icon: `star`,
    slug: `miscellaneous`,
    subItems: [
      {
        slug: `mailing-address`,
        key: `mailing-address`,
        label: `Mailing address`,
      },
    ],
  },
]

module.exports = {
  menuLinks,
  desktopBreakpoint,
  tabletBreakpoint,
  phoneBreakpoint,
  primaryColor,
  green6,
  blue6,
  red6,
  gold6,
}
