import { Typography } from "antd";
import { Link } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { SharingSteps } from "../../../../src/components/StepperExamples";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import React from 'react';
export default {
  Typography,
  Link,
  ExternalLink,
  SharingSteps,
  Copyable,
  InfoBox,
  React
};