import React, { memo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Icon from 'antd/es/icon'
import { message } from 'antd'
import './styles.less'


function Copyable({ children }) {

  const handleCopy = () => message.success('Copied to clipboard')

  return (
    <CopyToClipboard text={children}
                     onCopy={handleCopy}>
      <span className={'copyable'}>{children} <Icon type={`copy`}/></span>
    </CopyToClipboard>
  )
}


export default memo(Copyable)