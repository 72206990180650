import React, { useState } from 'react'
import { Icon, Modal, Typography, Button } from 'antd'
import './styles.less'

const { Paragraph } = Typography

function DocumentDialog(props) {

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleOpen = (evt) => {
    evt.preventDefault()
    setDialogOpen(true)
  }

  const handleClose = (evt) => {
    evt.preventDefault()
    setDialogOpen(false)
  }

  const { title, image, text, children } = props

  return (
    <span>
           <Button className={'document-dialog__link'} type={`link`} onClick={handleOpen}>
                  {children} <Icon style={{marginLeft: `4px`, marginTop: `-4px`}} type={`file-jpg`}/>
           </Button>
        <Modal
          className={`document-dialog__modal`}
          title={title}
          visible={dialogOpen}
          onCancel={handleClose}
          footer={null}
        >
          {image}
          <Paragraph>{text}</Paragraph>
        </Modal>
      </span>
  )
}


export default DocumentDialog