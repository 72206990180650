import { Typography } from "antd";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { ChangePasswordSteps } from "../../../../src/components/StepperExamples";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import React from 'react';
export default {
  Typography,
  ExternalLink,
  ChangePasswordSteps,
  Copyable,
  InfoBox,
  React
};