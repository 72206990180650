import React, { useContext } from 'react'
import { Drawer } from 'antd'
import SearchBar from '../SearchBar'
import Context from '../../contexts/Search'
import './styles.less'
import SearchResults from '../SearchResults'
import { phoneBreakpoint, tabletBreakpoint, desktopBreakpoint } from '../../constants'
import useWindowWidth from '../../hooks/useWindowWidth'

function getDrawerWidth(windowWidth) {
  if (windowWidth <= phoneBreakpoint) {
    return 280
  } else if (windowWidth <= tabletBreakpoint) {
    return 320
  } else if (windowWidth <= desktopBreakpoint) {
    return 360
  } else {
    return 420
  }
}

export default function SearchDrawer() {

  const { open, handleClose, query, handleChange, handleClear, results } = useContext(Context)
  const windowWidth = useWindowWidth()
  const drawerWidth = getDrawerWidth(windowWidth)

  return (
    <Drawer
      width={drawerWidth}
      closable={false}
      style={{ padding: 0 }}
      placement={`right`}
      visible={open}
      onClose={handleClose}
    >
      <SearchBar open={open}
                 query={query}
                 handleClose={handleClose}
                 handleClear={handleClear}
                 handleChange={handleChange}
      />
      <SearchResults
        query={query}
        results={results}
        handleClose={handleClose}
      />
    </Drawer>
  )
};

