import React from 'react'
import { Table } from 'antd'
import useWindowWidth from '../../hooks/useWindowWidth'
import Copyable from '../Copyable/Copyable'
import { tabletBreakpoint } from '../../constants'
import ExternalLink from '../ExternalLink/ExternalLink'



const columns = [{
  title: 'Name',
  dataIndex: 'title',
  key: 'title',
  render: titles => {
    return (
      <div>
        <div>{titles.substring(0,titles.indexOf(' '))}</div>
        <Copyable>{titles.substring(titles.indexOf(' ') + 1)}</Copyable>
      </div>)
  },
}, {
  title: 'Hours',
  dataIndex: 'hours',
  key: 'hours',
},
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location',
    render: code => <ExternalLink to={`https://ditu.amap.com/place/${code}`}>Map</ExternalLink>,
  },
]

const data = [{
  key: '1',
  title: `安家小厨 An Kitchen`,
  hours: `8:00am – 9:00pm`,
  location: `B000A85CQ3`,
}, {
  key: '2',
  title: `水木领航 Time Capsule Café`,
  hours: `8:00am – 8:30pm`,
  location: `B0FFHXGQ9F`,
}, {
  key: '3',
  title: `拾年咖啡 Ten Years After Café`,
  hours: `8:00am – 12:00am`,
  location: `B000A7P667`,
},
  {
    key: '4',
    title: `出壳咖啡 Chuke Coffee`,
    hours: `9:30am – 10:00pm`,
    location: `B000A96FVD`,
  },
  {
    key: '5',
    title: `星巴克 Starbucks`,
    hours: `8:00am - 6:00pm`,
    location: `B000A835IO`,
  },
]

function CoffeeTable() {
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth <= tabletBreakpoint
  const tableCols = isMobile
    ? columns.filter(obj => obj.key !== `hours`)
    : columns
  return (
    <Table pagination={false}
           style={{ width: `100%` }}
           size={isMobile ? `small` : `medium`}
           dataSource={data}
           columns={tableCols}
    />
  )
}

export default CoffeeTable