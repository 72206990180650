import { Typography, Tag } from "antd";
import { Link } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { ShopsTable } from "../../../../src/components/Tables";
import { primaryColor, green6, blue6 } from "../../../../src/constants";
import React from 'react';
export default {
  Typography,
  Tag,
  Link,
  ExternalLink,
  ShopsTable,
  primaryColor,
  green6,
  blue6,
  React
};