import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'
import { Registrar } from '../MapLocations'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "print-transcript"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Click <Copyable>打印成绩单申请</Copyable> on the landing page's sidebar (use Cmd+F to search the
    page).</div>,
  <div>
    Click the <Copyable>新加申请</Copyable> button at the top of the view.
  </div>,
  <div> Select number of copies and the target language. To print an English copy,
    choose <Copyable>英语成绩单</Copyable> from the drop down menu. Then click the <Copyable>提交</Copyable> button in the
    lower right corner of the view to submit your print request for review.</div>,
  <div> Wait until the status of your transcript print request has been changed from <Copyable>等待审核</Copyable> (awaiting
    review) to <Copyable>通过</Copyable> (approved).</div>,
  <div>Go to the registrar machines at the Registrar Center (<Registrar>see map</Registrar>). Tap <Copyable>成绩单打印</Copyable> on the machine's screen to view transcript printing details.</div>,
  <div>Tap <Copyable>现在打印</Copyable> to advance to the confirmation screen.</div>,
  <div>Tap <Copyable>扣费并打印</Copyable> to pay the fee (RMB 10 per copy) and print your transcript.</div>
]

export function PrintTranscriptSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}