import React from 'react'
import { Input, Icon, Button } from 'antd/lib/index'

import './styles.less'

export default function SearchBar(props) {
  const {query, open, handleClose, handleChange, handleClear} = props
  return (
    <Input
      autoFocus={open}
      value={query}
      onChange={handleChange}
      prefix={<Icon type={`search`}/>}
      suffix={<span>{query && <Button type="link" onClick={handleClear} icon={`delete`}/>} <Button type="link" onClick={handleClose} icon={`right`}/></span>}
      className={`searchbar`}
      placeholder={`Search…`}
    />
  )
};

