import React, { memo } from 'react'
import { Card } from 'antd'
import './styles.less'

const cardStyles = {
  blue: {
    headStyle: {
      color: `white`,
      background: `#1890ff`,
    },
    bodyStyle: {
      backgroundColor: `#e6f7ff`,
    },
  },
  red: {
    headStyle: {
      color: `white`,
      background: `#f5222d`,
    },
    bodyStyle: {
      backgroundColor: `#fff1f0`,
    },

  },
}

function InfoBox({ title, children, color = `blue` }) {

  return (
    <div className={`info-box`}>
      <Card
        title={title}
        headStyle={cardStyles[color][`headStyle`]}
        bodyStyle={cardStyles[color][`bodyStyle`]}
      >
        {children}
      </Card>
    </div>
  )
}

export default memo(InfoBox)