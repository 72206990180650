import { Divider, Typography } from "antd";
import EmailAddress from "../../../../src/components/EmailAddress";
import Checklist from "../../../../src/components/Checklist";
import ChecklistItem from "../../../../src/components/ChecklistItem";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import { ISSC, VisaBureau } from "../../../../src/components/MapLocations";
import { FormOfTemporaryResidence, JW202, AdmissionsCert, MedicalExam } from "../../../../src/components/DocumentExamples";
import React from 'react';
export default {
  Divider,
  Typography,
  EmailAddress,
  Checklist,
  ChecklistItem,
  Copyable,
  InfoBox,
  ISSC,
  VisaBureau,
  FormOfTemporaryResidence,
  JW202,
  AdmissionsCert,
  MedicalExam,
  React
};