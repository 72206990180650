import {
  ISSC,
  VisaBureau,
  Registrar,
  TravelHealthCareCenter,
  DormServiceDesk,
  PackageDeliveryCenter,
  LeeShauKee,
  CBuilding,
  ComprehensiveGym,
} from './MapLocations'

export {
  ISSC,
  VisaBureau,
  Registrar,
  TravelHealthCareCenter,
  DormServiceDesk,
  PackageDeliveryCenter,
  LeeShauKee,
  CBuilding,
  ComprehensiveGym,
}
