import { Typography } from "antd";
import Copyable from "../../../../src/components/Copyable";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { EmergencyTable } from "../../../../src/components/Tables";
import InfoBox from "../../../../src/components/InfoBox";
import { Divider } from 'antd';
import desktop from "../../../../src/assets/insurance-payment-desktop.pdf";
import mobile from "../../../../src/assets/insurance-payment-mobile.pdf";
import React from 'react';
export default {
  Typography,
  Copyable,
  ExternalLink,
  EmergencyTable,
  InfoBox,
  Divider,
  desktop,
  mobile,
  React
};