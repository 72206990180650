import { Divider, Typography } from "antd";
import UsefulAppsTable from "../../../../src/components/Tables/UsefulAppsTable";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { InternetSteps } from "../../../../src/components/StepperExamples";
import Copyable from "../../../../src/components/Copyable";
import { LeeShauKee } from "../../../../src/components/MapLocations";
import InfoBox from "../../../../src/components/InfoBox";
import React from 'react';
export default {
  Divider,
  Typography,
  UsefulAppsTable,
  ExternalLink,
  InternetSteps,
  Copyable,
  LeeShauKee,
  InfoBox,
  React
};