import { Typography } from "antd";
import { Link } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { CoffeeTable } from "../../../../src/components/Tables";
import React from 'react';
export default {
  Typography,
  Link,
  ExternalLink,
  CoffeeTable,
  React
};