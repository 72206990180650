import SoftwareTable from './SoftwareTable'
import UsefulAppsTable from './UsefulAppsTable'
import CanteenTable from './CanteenTable'
import ShopsTable from './ShopsTable'
import CoffeeTable from './CoffeeTable'
import EmergencyTable from './EmergencyTable'
import OfficesTable from './OfficesTable'
import QRTable from './QRTable/QRTable'

export {
  SoftwareTable,
  UsefulAppsTable,
  CanteenTable,
  ShopsTable,
  CoffeeTable,
  EmergencyTable,
  OfficesTable,
  QRTable
}