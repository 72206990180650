import { Divider, Typography } from "antd";
import { Link } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import Checklist from "../../../../src/components/Checklist";
import ChecklistItem from "../../../../src/components/ChecklistItem";
import InfoBox from "../../../../src/components/InfoBox";
import { CBuilding, ISSC } from "../../../../src/components/MapLocations";
import { FormOfTemporaryResidence, AdmissionsCert } from "../../../../src/components/DocumentExamples";
import React from 'react';
export default {
  Divider,
  Typography,
  Link,
  ExternalLink,
  Checklist,
  ChecklistItem,
  InfoBox,
  CBuilding,
  ISSC,
  FormOfTemporaryResidence,
  AdmissionsCert,
  React
};