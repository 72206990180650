import React, { useState } from 'react'
import Drawer from 'rc-drawer'
import Menu from '../Menu'
import 'rc-drawer/assets/index.css'
import './styles.less'

export default function TemporarySidebar() {
  const [open, setOpen] = useState(false)

  const handleChange = () => setOpen(!open)
  const handleClose = () => setOpen(false)

  return (
    <div className={`temporary-sidebar`}>
      <Drawer onHandleClick={handleChange}
              onClose={handleClose}
              open={open} width={`224px`}
      >
        <Menu handleClose={handleClose}/>
      </Drawer>
    </div>
  )
}

