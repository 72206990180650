import React, { memo, useContext } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Menu, Icon } from 'antd'

import PathnameContext from '../../contexts/Pathname'
import Logo from '../Logo'
import './styles.less'

const { Item, SubMenu } = Menu
const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        menuLinks {
          label
          slug
          key
          icon
          subItems {
            label
            slug
            key
          }
        }
      }
    }
  }
`


function menu({ handleClose }) {

  const pathname = useContext(PathnameContext)

  const handleClick = () => {

    // collapse on touch
    if (handleClose) {
      handleClose()
    }
  }

  const keys = pathname.split(`/`)
  const currentKey = keys.pop()
  const selectedKey = currentKey ? currentKey : `home`
  const { site } = useStaticQuery(query)

  return (
    <Menu
      onClick={handleClick}
      defaultSelectedKeys={[selectedKey]}
      defaultOpenKeys={pathname.split(`/`)}
      selectedKeys={[selectedKey]}
      className={`menu`}
      mode={`inline`}
    >
      <Logo/>
      {site.siteMetadata.menuLinks.map(obj => {
        const { label, slug, icon, key } = obj
        return obj[`subItems`]
          ? <SubMenu key={key}
                     title={<span><Icon type={icon}/> {label}</span>}>
            {obj.subItems.map((item) => (
              <Item key={item.key}><Link to={`/${obj.slug}/${item.slug}`}>{item.label}</Link></Item>))}
          </SubMenu>
          : <Item key={key}>
            <Link to={`/${slug}`}>
              <span><Icon type={icon}/> {label}</span>
            </Link>
          </Item>
      })}
    </Menu>
  )
}

export default memo(menu)