import { Typography } from "antd";
import { Link } from "gatsby";
import ExternalLink from "../../../../src/components/ExternalLink/ExternalLink";
import { ICTopupSteps } from "../../../../src/components/StepperExamples/ICTopupSteps";
import { CBuilding } from "../../../../src/components/MapLocations";
import React from 'react';
export default {
  Typography,
  Link,
  ExternalLink,
  ICTopupSteps,
  CBuilding,
  React
};