import React, { useState } from 'react'
import { Icon, Modal, Button, Typography } from 'antd'
import Map from './Map'
import Copyable from '../Copyable'
import './styles.less'

const { Paragraph } = Typography

function MapDialog(props) {

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const { title, lat, long, englishAddress, chineseAddress, children, amapId } = props

  return (
    <span className={`map-dialog`}>
        <Button className={'map-dialog__link'} type={`link`} onClick={handleOpen}>{children}<Icon
          style={{ marginLeft: `4px` }}
          type={`compass`}/></Button>
        <Modal
          className={`map-dialog__modal`}
          title={title}
          visible={dialogOpen}
          onCancel={handleClose}
          footer={[
            <Button key={'primary'} type={'primary'}>
              <a href={`https://ditu.amap.com/place/${amapId}`} target={'_blank'}>
                Open in new tab
              </a>
            </Button>,
          ]}
        >
          <Map lat={lat} long={long}/>
            <Paragraph><strong>Address:{' '}</strong>{englishAddress}<br/>
              <strong>地址:</strong>{' '}<Copyable>{chineseAddress}</Copyable>
            </Paragraph>
        </Modal>
      </span>
  )
}

export default MapDialog