import { Typography } from "antd";
import BannerImage from "../../../../src/components/BannerImage";
import Copyable from "../../../../src/components/Copyable";
import EmailAddress from "../../../../src/components/EmailAddress";
import { CBuilding } from "../../../../src/components/MapLocations";
import React from 'react';
export default {
  Typography,
  BannerImage,
  Copyable,
  EmailAddress,
  CBuilding,
  React
};