import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "change-password"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Select <Copyable>修改密码</Copyable> change password from the dropdown menu in the upper right corner of the landing page.</div>,
  <div>Enter your new password in the field to the right of <Copyable>输入新密码</Copyable> (the third field from the top), then retype it in the <Copyable>再次输入新密码</Copyable> field directly below.</div>,

]

export function ChangePasswordSteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      <Step description={<div className={`step-contents`}>Sign in at <ExternalLink
        to={`http://info.tsinghua.edu.cn`}>info.tsinghua.edu.cn</ExternalLink>. This process is easier on a desktop
        browser (i.e. mobile browsers may not be supported).</div>}/>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
      <Step description={<div className={`step-contents`}>Click the <Copyable>保存密码</Copyable> save password button when you’re all set.</div>}/>
    </Stepper>)
}