import { Link } from "gatsby";
import { Divider } from "antd";
import EmailAddress from "../../../../src/components/EmailAddress";
import ExternalLink from "../../../../src/components/ExternalLink";
import Checklist from "../../../../src/components/Checklist";
import ChecklistItem from "../../../../src/components/ChecklistItem";
import Copyable from "../../../../src/components/Copyable";
import InfoBox from "../../../../src/components/InfoBox";
import { TravelHealthCareCenter } from "../../../../src/components/MapLocations";
import { AdmissionsCert, JW202 } from "../../../../src/components/DocumentExamples";
import application from "../../../../src/assets/visa-application-v2013.pdf";
import React from 'react';
export default {
  Link,
  Divider,
  EmailAddress,
  ExternalLink,
  Checklist,
  ChecklistItem,
  Copyable,
  InfoBox,
  TravelHealthCareCenter,
  AdmissionsCert,
  JW202,
  application,
  React
};