import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Steps } from 'antd'

import Stepper from '../Stepper'
import ExternalLink from '../ExternalLink/ExternalLink'
import Copyable from '../Copyable/Copyable'

const { Step } = Steps

const imagesQuery = graphql`
  {
    allFile(
      sort: { order: ASC, fields: name},
      filter:{relativeDirectory: {eq: "apply"}}
    ) {
      nodes {
        ...GatsbyFluidImageQuery
      }
    }
  }
`

const stepInstructions = [
  <div>Go to the <ExternalLink to={`http://gradadmission.tsinghua.edu.cn`}>sign-in page</ExternalLink> and click the yellow Sign Up button in the lower-right corner.</div>,
  <div>Fill in the fields to create your account. Note that the email address you enter will be used to activate your account. <strong>Your password must be at least six characters in length and can only contain English letters and numbers</strong>.</div>, <div>Read the Declaration. If you accept the terms, check the Accept button on the right and then click the Submit button at the bottom.</div>,
  <div>Check your mailbox for an activation email from grad@tsinghua.edu.cn with the subject: <Copyable>清华大学研究生申请系统 Tsinghua University Graduate Programs Application System for International Students</Copyable>. Click the link in the message body to activate your account. </div>,
  <div>Head back to the login page and sign in to your account using your email address and password.</div>,
  <div>When you reach the Applications section of the application, select the relevant program in field in the First Dept./School dropdown. For example, if you're applying to the <ExternalLink to={`http://gix.tsinghua.edu.cn/Home/index.htm`}>Global Innovation Exchange program</ExternalLink>, select <Copyable>清华大学全球创新学院 Global Innovation eXchange Institute，Tsinghua University</Copyable>. See the fields in the screenshot below as a reference.</div>,
]

export function ApplySteps() {
  const { allFile } = useStaticQuery(imagesQuery)
  return (
    <Stepper>
      {
        allFile.nodes.map(({ childImageSharp }, idx) => {
          const { id, fluid } = childImageSharp
          return (<Step
            key={id}
            description={<div className={`step-contents`}>{stepInstructions[idx]}<Img fluid={fluid}/></div>}
          />)
        })
      }
    </Stepper>)
}